import { Global } from '@mantine/core';

import Thin from './Montserrat-Thin.ttf'
import ThinItalic from './Montserrat-ThinItalic.ttf'
import ExtraLight from './Montserrat-ExtraLight.ttf'
import ExtraLightItalic from './Montserrat-ExtraLight.ttf'
import Light from './Montserrat-Light.ttf'
import LightItalic from './Montserrat-LightItalic.ttf'
import Regular from './Montserrat-Regular.ttf'
import RegularItalic from './Montserrat-Italic.ttf'
import Medium from './Montserrat-Medium.ttf'
import MediumItalic from './Montserrat-MediumItalic.ttf'
import SemiBold from './Montserrat-SemiBold.ttf'
import SemiBoldItalic from './Montserrat-SemiBoldItalic.ttf'
import Bold from './Montserrat-Bold.ttf'
import BoldItalic from './Montserrat-BoldItalic.ttf'
import ExtraBold from './Montserrat-ExtraBold.ttf'
import ExtraBoldItalic from './Montserrat-ExtraBoldItalic.ttf'
import Black from './Montserrat-Black.ttf'
import BlackItalic from './Montserrat-BlackItalic.ttf'

export default function MontserratFonts() {
  return (
    <Global
      styles={[
        {
            '@font-face': {
                fontFamily: 'Montserrat',
                src: `url('${Thin}') format("truetype")`,
                fontWeight: 100,
                fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
                fontFamily: 'Montserrat',
                src: `url('${ThinItalic}') format("truetype")`,
                fontWeight: 100,
                fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${ExtraLight}') format("truetype")`,
              fontWeight: 200,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${ExtraLightItalic}') format("truetype")`,
              fontWeight: 200,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${Light}') format("truetype")`,
              fontWeight: 300,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${LightItalic}') format("truetype")`,
              fontWeight: 300,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${Regular}') format("truetype")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${RegularItalic}') format("truetype")`,
              fontWeight: 400,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${Medium}') format("truetype")`,
              fontWeight: 500,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${MediumItalic}') format("truetype")`,
              fontWeight: 500,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${SemiBold}') format("truetype")`,
              fontWeight: 600,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${SemiBoldItalic}') format("truetype")`,
              fontWeight: 600,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${Bold}') format("truetype")`,
              fontWeight: 700,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${BoldItalic}') format("truetype")`,
              fontWeight: 700,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${ExtraBold}') format("truetype")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${ExtraBoldItalic}') format("truetype")`,
              fontWeight: 800,
              fontStyle: 'italic',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${Black}') format("truetype")`,
              fontWeight: 900,
              fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
              fontFamily: 'Montserrat',
              src: `url('${BlackItalic}') format("truetype")`,
              fontWeight: 900,
              fontStyle: 'italic',
            },
        },
      ]}
    />
  );
}