import { createStyles, Container, Group, Anchor, Text, rem, Space, Center } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';


const useStyles = createStyles((theme) => ({
    footer: {
        backgroundColor: '#080B18'
    },
      
    container: {
        paddingTop: rem(65),
        paddingBottom: rem(20),
        [theme.fn.smallerThan('md')]: {
            paddingTop: rem(65),
            paddingBottom: rem(20),
        },
    },
}))


export default function SappotFooter() {
    const { classes } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');

    return (
        <div className={classes.footer}>
        <Container className={classes.container} size={'xl'}>
            <Center>
                <Group spacing={matches?30:20}>
                    <Anchor component={Link} to='/'
                    c="#FFFFFF"
                    size={matches?15:14}
                    fw={500}
                    >
                        Home
                    </Anchor>
            
                    <Anchor component={Link} to='/about'
                    c="#FFFFFF"
                    size={matches?15:14}
                    fw={500}
                    >
                        About Us
                    </Anchor>
        
                    <Anchor component={Link} to='/contact'
                    c="#FFFFFF"
                    size={matches?15:14}
                    fw={500}
                    >
                        Contact Us
                    </Anchor>
                </Group>
            </Center>
            <Space h={matches?65:65}/>
            <Center>
                <Text size={10} color='#FFFFFF' align='center' lh={'normal'}>© 2023 Sappot IT Sdn. Bhd. All rights reserved.</Text>
            </Center>
        </Container>
        </div>
    );
}