import React from 'react'
import { Outlet } from 'react-router-dom'
import { MantineProvider, AppShell } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import SappotHeader from './SappotHeader';
import SappotFooter from './SappotFooter';

import MontserratFonts from '../../assets/fonts/MontserratFonts';

export default function SappotAppShell() {

  return (
    <MantineProvider 
        theme={{ 
        fontFamily: 'Montserrat, sans-serif',
        colorScheme: 'light',
        colors: {
            brand: ["#defdff","#cbf3ff","#9ce5fc","#69d6f9","#3fc9f6","#23c1f4","#00bdf4","#00a6da","#0095c4","#0081ae"],
        },
        primaryColor: 'brand',
        activeStyles: { transform: 'scale(0.98)' }}} withGlobalStyles withNormalizeCSS>
            <MontserratFonts />
            <Notifications position="top-center" zIndex={2077}/>
                <AppShell
                    padding={0}
                    header={<SappotHeader />}
                    footer={<SappotFooter />}
                    styles={(theme) => ({
                        main: { backgroundColor: '#0B1023' },
                    })}
                >
                <Outlet />
            </AppShell>
    </MantineProvider>
  )
}
