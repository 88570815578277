import { UnstyledButton, Text, createStyles, Group } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
        padding: '16px 40px',
        backgroundColor: '#34C6F5',
        borderRadius: 1000,
        color: '#FFF',
        '&:hover': {
            backgroundColor: theme.colors.brand[8],
        },

        [theme.fn.smallerThan('sm')]: {
            padding: '14px 30px',
        },
        transition: 'all 0.25s ease',
    },
    invisible: {
        backgroundColor: 'rgba(0,0,0,0)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
            color: '#34C6F5'
        },
        
    },
    noPadding: {
        padding: '0px 0px',
        [theme.fn.smallerThan('sm')]: {
            padding: '0px 0px',
        },
    },
    btnText: {
        fontSize: 15,
        lineHeight: 'normal',
        fontWeight: '500',
        // color: '#FFF',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },
    btnTextDark: {
        color: '#0B1023',
    },
}));

export default function CustomButton({title='Button', onClick, isNoBG=false, isDark=false, isChevron=false}) {
    const { classes, cx } = useStyles()


    return (
        <UnstyledButton onClick={onClick} className={cx(classes.root, {[classes.invisible]: isNoBG === true}, {[classes.noPadding]: isDark ===true})}>
            <Group spacing={8}>
                <Text className={cx(classes.btnText, {[classes.btnTextDark]: isDark === true})}>{title}</Text>
                {isChevron ?
                    <IconChevronRight className={cx(classes.btnText, {[classes.btnTextDark]: isDark === true})} size={16}/>
                : null}
            </Group>
        </UnstyledButton>
    );
}