import { BackgroundImage, Container, createStyles, Text, rem, Grid, Image,  } from '@mantine/core'
import React from 'react'

import AboutHeroBG from '../assets/images/AboutHeroBG.png'

import About01 from '../assets/images/About01.png'
import About02 from '../assets/images/About02.png'
import About03 from '../assets/images/About03.png'
import About04 from '../assets/images/About04.png'

import Dots from '../assets/misc/Dots.png'


import { HEADER_HEIGHT } from '../utils/Constants';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },

    heroBG: {
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: -HEADER_HEIGHT,
        backgroundColor: '#0B1023',
    },

    heroSection: {
        minHeight: `calc(50svh)`,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        paddingTop: HEADER_HEIGHT,
        display: 'flex',
    },

    heroContentContainer: {
        marginTop: 40,
        marginBottom: 40,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 40,
    },

    heroTitle: {
        fontSize: 50,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 34,
        },
    },

    sectionAfterHero: {
        paddingTop: `calc(50svh - ${rem(HEADER_HEIGHT)})`,
    },

    sectionContainer: {
        paddingTop: 120,
        paddingBottom: 30,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 80,
            paddingBottom: 20,
        },
    },

    section01Wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 60,
        [theme.fn.smallerThan('lg')]: {
            gap: 40,
        },
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 40,
        },
    },

    section: {
        paddingTop: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
    },

    sectionTitle: {
        fontSize: 40,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 30,
        },
    },

    sectionDesc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#CACACA',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    section01ContentWrapper: {
        display: 'flex',
        gap: 40,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 40,
        },
    },

    section01Content02Wrapper: {
        flex: 3, display: 'flex', flexDirection: 'column', 
        gap: 20,
        [theme.fn.smallerThan('sm')]: {
            gap: 20,
        },
    },

    section01Title: {
        fontSize: 50,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        [theme.fn.smallerThan('sm')]: {
            fontSize: 34,
        },
    },
    section01Desc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#FAFAFA',
        textAlign: 'justify',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
            width: '100%',
        },
    },

    section01Shape: {
        width: '50%',
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    section02ContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 30,
        [theme.fn.smallerThan('sm')]: {
            // flexDirection: 'column',
            gap: 10,
        },
    },

    section02Content01Wrapper: {
        flex: 4, display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'flex-end',
        [theme.fn.smallerThan('md')]: {
            gap: 20,
        },
        [theme.fn.smallerThan('sm')]: {
            gap: 10,
        },
    },

    section02Content02Wrapper: {
        flex: 6, display: 'flex', flexDirection: 'column', gap: 30,
        [theme.fn.smallerThan('md')]: {
            gap: 20,
        },
        [theme.fn.smallerThan('sm')]: {
            gap: 10,
        },
    },

    section02Dots01: {
        position: 'absolute',zIndex: -1, 
        width: 185, bottom: -85, left: -85,

        [theme.fn.smallerThan('md')]: {
            width: 120, bottom: -60, left: -60,
        },
        [theme.fn.smallerThan('xs')]: {
            width: 50, bottom: -25, left: -25,
        },
    },

    section02Dots02: {
        position: 'absolute',zIndex: -1, 
        width: 185, top: -85, right: -85,

        [theme.fn.smallerThan('md')]: {
            width: 120, top: -60, right: -60,
        },
        [theme.fn.smallerThan('xs')]: {
            width: 50, top: -25, right: -25,
        },
    },

    section02Dots03: {
        position: 'absolute',zIndex: -1, 
        width: 185, top: -211, right: -211,

        [theme.fn.smallerThan('md')]: {
            width: 120, top: -120, right: -120,
        },
        [theme.fn.smallerThan('xs')]: {
            width: 50, top: -50, right: -50,
        },
    },

    section03ContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 78,
        paddingRight: 78,
        gap: 80,
        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            gap: 40,
        },
    },

    section03Title: {
        fontSize: 36,
        fontWeight: 800,
        color: '#FFF',
        lineHeight: 'normal',
        paddingBottom: 40,
        [theme.fn.smallerThan('md')]: {
            fontSize: 28,
            paddingBottom: 20,
        },
    },

    section03Desc: {
        fontSize: 16,
        fontWeight: 400,
        color: '#FAFAFA',
        lineHeight: 'normal',
        [theme.fn.smallerThan('md')]: {
            fontSize: 14,
        },
    },

    section03spacing: {
        flex: 2,
        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        }
    },

    section03Line: {
        height: 3, width: 80, backgroundColor: '#34C6F5', marginTop: 8, flexShrink: 0,
        [theme.fn.smallerThan('sm')]: {
            width: 60
        },
        [theme.fn.smallerThan('xs')]: {
            width: 40
        }
    },

    section04Container: {
        paddingTop: 90,
        paddingBottom: 120,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 60,
            paddingBottom: 80,
        },
    },

}));


export default function About() {
    const { classes } = useStyles()

    return (
        <div className={classes.root}>
            {/* Hero */}
            <BackgroundImage
                src={AboutHeroBG}
                className={classes.heroBG}
            >
                <div className={classes.heroSection}>
                    <Container size={'xl'} className={classes.heroContentContainer}>
                        <Text className={classes.heroTitle}>About Us</Text>
                    </Container>
                </div>
            </BackgroundImage>
            {/* Section 01 */}
            <div className={classes.sectionAfterHero}>
                <Container size={'xl'} className={classes.sectionContainer}>
                    <div className={classes.section01ContentWrapper}>
                        <div style={{flex: 3}}>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Our Story</Text>
                            <Text className={classes.section01Title}>We’re tech enthusiasts dedicated to improving your business.</Text>
                        </div>
                        <div className={classes.section01Content02Wrapper}>
                            <Text className={classes.section01Desc} >
                                Established in 2022, Sappot Tech is a digital pioneer, founded on the belief that the seamless integration of technology and creativity can redefine the digital landscape. Our journey is one of continual innovation, marked by a dedicated team of designers and developers committed to pushing the boundaries of web and mobile development.
                                <br/><br/>
                                With each project, we've grown and refined our approach, earning a reputation for delivering cutting-edge solutions and fostering genuine client relationships. Today, Sappot Tech stands as a trusted name, driven by a commitment to excellence and the transformative power of technology. Join us on this exciting journey as we continue to be catalysts for digital evolution, shaping a future where creativity knows no bounds and technology unlocks limitless possibilities.
                            </Text>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Section 02 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <div className={classes.section02ContentWrapper}>
                    <div className={classes.section02Content01Wrapper}>
                        <div>
                            <img style={{width: '100%', filter: 'brightness(80%)'}}src={About01} alt='About01'/>
                        </div>
                        <div style={{position: 'relative', width: '60%', zIndex: 1}}>
                            <img className={classes.section02Dots01} src={Dots} alt=''/>
                            <img style={{width: '100%', filter: 'brightness(80%)'}} src={About02} alt='About02'/>
                        </div>
                    </div>
                    <div className={classes.section02Content02Wrapper}>
                        <div style={{position: 'relative', width: '60%', zIndex: 1}}>
                            <img className={classes.section02Dots02} src={Dots} alt=''/>
                            <img className={classes.section02Dots03} src={Dots} alt=''/>
                            <img style={{width: '100%', filter: 'brightness(80%)'}} src={About03} alt='About03'/>
                        </div>
                        <img style={{width: '100%', filter: 'brightness(80%)'}} src={About04} alt='About04'/>
                    </div> 
                </div>
            </Container>
            {/* Section 03 */}
            <Container size={'xl'} className={classes.section04Container}>
                <div className={classes.section03ContentWrapper}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 8}}>
                            <Text className={classes.section03Title}>Our Vision</Text>
                            <div style={{display: 'flex', gap: 40}}>
                                <div className={classes.section03Line}/>
                                <Text className={classes.section03Desc}>
                                    At Sappot Tech, our vision is a digital landscape where businesses and individuals thrive with seamless online experiences. We are dedicated to crafting exceptional websites and mobile apps that elevate our clients' digital presence. We aspire to be the trusted partner in their journey, combining creativity and technology to redefine possibilities and ensure lasting success in the ever-evolving digital world.
                                    <br/><br/>
                                    At the core of our vision is a commitment to excellence, integrity, and customer satisfaction. We see ourselves as catalysts for growth, empowering clients to navigate and excel in the online realm. Together, we're building a digital world where creativity knows no bounds, and technology becomes a powerful tool for transforming ideas into reality.
                                </Text>
                            </div>
                        </div>
                        <div className={classes.section03spacing}/>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div className={classes.section03spacing}/>
                        <div style={{flex: 8}}>
                            <Text className={classes.section03Title}>Our Mission</Text>
                            <div style={{display: 'flex', gap: 40, }}>
                                <div className={classes.section03Line}/>
                                <Text className={classes.section03Desc}>
                                    Our mission is to empower businesses and individuals with unparalleled digital solutions. We are dedicated to delivering innovative websites and mobile apps that not only meet but exceed our clients' expectations. Through a seamless integration of creativity and technology, we strive to enhance our clients' online presence, foster growth, and propel them to success in the ever-evolving digital landscape.
                                    <br/><br/>
                                    Driven by a commitment to excellence, integrity, and client satisfaction, we aim to be more than a service provider – we aspire to be strategic partners in our clients' digital journey. Our mission extends beyond delivering products; we are dedicated to guiding our clients through the dynamic world of digital transformation, offering tailored solutions that unlock their full potential. At Sappot Tech, we are passionate about building a future where digital experiences are not just functional but transformative, creating lasting value for our clients and their audiences.
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            {/* Section 04 */}
            {/* <Container size={'xl'} className={classes.section04Container}>
                <Text className={classes.sectionTitle} pb={5}>Meet Our Team</Text>
                <Text className={classes.sectionDesc} pb={80}>Get to know the talented individuals behind Sappot Tech.</Text>
                <Container size={'md'}>
                    <Grid gutter={60}>
                        <Grid.Col xs={12} sm={6} md={4} lg={4}>
                            <Image width={'100%'} height={300} radius={5} withPlaceholder mb={25} alt='Kevin Ooi'/>
                            <Text size={18} fw={600} color='#FFF' lh={'normal'} align='center' pb={8}>Kevin Ooi</Text>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} align='center'>Director</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4} lg={4}>
                            <Image width={'100%'} height={300} radius={5} withPlaceholder mb={25} alt='Jonas Tan'/>
                            <Text size={18} fw={600} color='#FFF' lh={'normal'} align='center' pb={8}>Jonas Tan</Text>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} align='center'>CEO</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4} lg={4}>
                            <Image width={'100%'} height={300} radius={5} withPlaceholder mb={25} alt='Gary Wong'/>
                            <Text size={18} fw={600} color='#FFF' lh={'normal'} align='center' pb={8}>Gary Wong</Text>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} align='center'>CTO</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4} lg={4}>
                            <Image width={'100%'} height={300} radius={5} withPlaceholder mb={25} alt='Kai Wen'/>
                            <Text size={18} fw={600} color='#FFF' lh={'normal'} align='center' pb={8}>Kai Wen</Text>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} align='center'>Software Developer</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4} lg={4}>
                            <Image width={'100%'} height={300} radius={5} withPlaceholder mb={25} alt='Yew Ming'/>
                            <Text size={18} fw={600} color='#FFF' lh={'normal'} align='center' pb={8}>Yew Ming</Text>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} align='center'>Software Developer</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4} lg={4}>
                            <Image width={'100%'} height={300} radius={5} withPlaceholder mb={25} alt='Oscar Chin'/>
                            <Text size={18} fw={600} color='#FFF' lh={'normal'} align='center' pb={8}>Oscar Chin</Text>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} align='center'>UI/UX Designer</Text>
                        </Grid.Col>
                    </Grid>
                </Container>
            </Container> */}
        </div>
    )
}
