import { BackgroundImage, Container, createStyles, Text, Box, rem, Group, Grid, Image, Rating, Avatar, Accordion, Center, List  } from '@mantine/core'
import { Carousel } from '@mantine/carousel';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';

import CustomButton from '../components/general/CustomButton';

import HomeHeroBG from '../assets/images/HomeHeroBG.png'

import Service01 from '../assets/images/Service01.png'
import Service02 from '../assets/images/Service02.png'
import Service03 from '../assets/images/Service03.png'

import Key01 from '../assets/images/Key01.png'
import Key02 from '../assets/images/Key02.png'
import Key03 from '../assets/images/Key03.png'

import { HEADER_HEIGHT } from '../utils/Constants';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
        // backgroundColor: '',
    },

    heroBG: {
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: -HEADER_HEIGHT,
        backgroundColor: '#0B1023',
    },

    heroSection: {
        // minHeight: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
        minHeight: `calc(100svh)`,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        paddingTop: HEADER_HEIGHT,
        display: 'flex',
    },

    heroContentContainer: {
        // marginTop: -HEADER_HEIGHT,
        marginTop: 40,
        marginBottom: 40,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 40,
    },

    heroTitle: {
        fontSize: 50,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        width: 500,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 34,
            width: '100%',
        },
    },
    heroDesc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#FAFAFA',
        width: 600,
        textAlign: 'justify',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
            width: '100%',
        },
    },

    sectionAfterHero: {
        paddingTop: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
    },

    sectionContainer: {
        paddingTop: 120,
        paddingBottom: 30,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 80,
            paddingBottom: 20,
        },
    },

    section01ContentWrapper: {
        display: 'flex',
        gap: 40,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 40,
        },
    },

    section01Content02Wrapper: {
        flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', 
        [theme.fn.smallerThan('sm')]: {
            gap: 40,
        },
    },

    section: {
        paddingTop: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
    },

    sectionTitle: {
        fontSize: 40,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 30,
        },
    },

    sectionDesc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#CACACA',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    section02ContentWrapper: {
        paddingTop: 80,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 40,
        },
    },

    section02Box01: {
        backgroundColor: '#FFF', width: '100%', borderRadius: 5, overflow: 'hidden', display: 'flex',
    },

    section02Box02: {
        height: '100%',
        backgroundColor: '#FFF', width: '100%', borderRadius: 5, overflow: 'hidden', display: 'flex', flexDirection: 'column'
    },

    section03ContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 120,
        paddingLeft: 78,
        paddingRight: 78,
        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            gap: 80,
        },
    },

    section03Box01: {
        display: 'flex', gap: 100,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 50
        },
    },

    section03Box02: {
        display: 'flex', gap: 100, flexDirection: 'row-reverse',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 50
        },
    },

    section03ImageWapper: {
        position: 'relative', width: 450, height: 450,
        zIndex: 1,
        [theme.fn.smallerThan('md')]: {
            width: 350, height: 350,
        },
        [theme.fn.smallerThan('sm')]: {
            width: '100%'
        },
    },

    section03ImageOffsetBorder: {
        position: 'absolute', width: 450, height: 450, backgroundColor: '#181F3B', bottom: -25, right: -25,
        zIndex: -1,
        [theme.fn.smallerThan('md')]: {
            width: 350, height: 350,
        },
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            bottom: -10, right: -10,
        },
    },

    section03Image: {
        width: 450, height: 450,
        objectFit: 'cover',
        filter: 'brightness(80%)', 
        // zIndex: 2,
        [theme.fn.smallerThan('md')]: {
            width: 350, height: 350,
        },
        [theme.fn.smallerThan('sm')]: {
            width: '100%'
        },
    },

    section03Title: {
        fontSize: 36,
        fontWeight: 700,
        color: '#FFF',
        lineHeight: 'normal',
        paddingBottom: 40,
        [theme.fn.smallerThan('md')]: {
            fontSize: 28,
        },
    },

    section03Desc: {
        fontSize: 16,
        fontWeight: 400,
        color: '#FAFAFA',
        lineHeight: 'normal',
        [theme.fn.smallerThan('md')]: {
            fontSize: 14,
        },
    },

    section06Container: {
        paddingTop: 90,
        paddingBottom: 120,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 60,
            paddingBottom: 80,
        },
    },

    section06Title: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: 'normal',
        color: '#FFF',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 18,
        },
    },

    section06Desc: {
        fontSize: 15,
        fontWeight: 400,
        color: '#FAFAFA',
        lineHeight: 'normal',
        textAlign: 'center',
        [theme.fn.smallerThan('md')]: {
            fontSize: 14,
        },
    },

}));

export const TESTIMONIAL = [
    {
        rating: 5,
        review: `Working with Sappot Tech was a game-changer for our business. Their expertise and dedication to delivering high-quality websites and mobile applications exceeded our expectations.`,
        logo: require('../assets/logo/XTENZLogo.png'),
        name: 'Carlson Chia',
        position: 'Founder',
        company: 'XTENZ Car Warranty',
    },
    {
        rating: 5,
        review: `Working with Sappot Tech was a game-changer for our business. Their expertise and dedication to delivering high-quality websites and mobile applications exceeded our expectations.`,
        logo: require('../assets/logo/XTENZLogo.png'),
        name: 'Carlson Chia',
        position: 'Founder',
        company: 'XTENZ Car Warranty',
    },
    {
        rating: 5,
        review: `Working with Sappot Tech was a game-changer for our business. Their expertise and dedication to delivering high-quality websites and mobile applications exceeded our expectations.`,
        logo: require('../assets/logo/XTENZLogo.png'),
        name: 'Carlson Chia',
        position: 'Founder',
        company: 'XTENZ Car Warranty',
    },
]

export default function Home() {
    const { classes } = useStyles()
    const navigate = useNavigate()

    return (
        <div className={classes.root}>
            {/* Hero */}
            <BackgroundImage
                src={HomeHeroBG}
                className={classes.heroBG}
            >
                <div className={classes.heroSection}>
                    <Container size={'xl'} className={classes.heroContentContainer}>
                        <Text className={classes.heroTitle}>Building Websites and Mobile Apps That Inspire</Text>
                        <Text className={classes.heroDesc}>At Sappot Tech, we specialize in developing innovative websites and mobile applications that deliver exceptional user experiences. Our team of experts combines cutting-edge technology with creative design to bring your ideas to life.</Text>
                        <Box>
                            <CustomButton title='Learn More' onClick={()=>navigate('/service')}/>
                        </Box>
                    </Container>
                </div>
            </BackgroundImage>
            {/* Section 01 */}
            <div className={classes.sectionAfterHero}>
                <Container size={'xl'} className={classes.sectionContainer}>
                    <div className={classes.section01ContentWrapper}>
                        <div style={{flex: 1}}>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Innovate</Text>
                            <Text className={classes.heroTitle} style={{width: '100%'}}>Empowering Businesses Through Cutting-Edge Solutions</Text>
                        </div>
                        <div className={classes.section01Content02Wrapper}>
                            <Text className={classes.heroDesc} style={{width: '100%'}}>At Sappot Tech, our mission is to provide innovative website and mobile application development solutions that empower businesses to succeed in the digital world. We are driven by our vision to be the leading provider of technology solutions, delivering exceptional value to our clients. Our core values of excellence, integrity, and collaboration guide everything we do.</Text>
                            <Group>
                                <CustomButton title='Learn More' onClick={()=>navigate('/service')}/>
                                <CustomButton title='Contact Us' onClick={()=>navigate('/contact')} isNoBG/>
                            </Group>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Section 02 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <Text className={classes.sectionTitle} pb={5}>Our Services</Text>
                <Text className={classes.sectionDesc}>Transforming Ideas into Powerful Digital Solutions</Text>
                <div className={classes.section02ContentWrapper}>
                    <Grid gutter={30}>
                        <Grid.Col sm={12} md={12} lg={5}>
                            <div className={classes.section02Box01}>
                                <div style={{flex: 1}}>
                                    <Image width={'100%'} height={410} style={{filter: 'brightness(80%)'}} src={Service01} alt="Expertise" withPlaceholder />
                                </div>
                                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                                    <div style={{padding: 25}}>
                                        <Text size={16} fw={500} color='#292F83' lh={'normal'} pb={15}>Expertise</Text>
                                        <Text size={24} fw={700} color='#0B1023' lh={'normal'} pb={15}>Expert Consultancy Services</Text>
                                        <Text size={15} fw={500} color='#0B1023' lh={'normal'} pb={30}>Custom Solution tailored to your business needs.</Text>
                                        <CustomButton title='Learn More' onClick={()=>navigate('/service')} isNoBG isDark isChevron/>
                                    </div>
                                </div>
                            </div>
                        </Grid.Col>
                        <Grid.Col sm={6} md={6} lg="auto">
                            <div className={classes.section02Box02}>
                                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                                    <div style={{padding: 25}}>
                                        <Text size={16} fw={500} color='#292F83' lh={'normal'} pb={15}>Revolutionize</Text>
                                        <Text size={24} fw={700} color='#0B1023' lh={'normal'} pb={15}>Custom Websites</Text>
                                        <Text size={15} fw={500} color='#0B1023' lh={'normal'} pb={30}>Creating Seamless Mobile Experiences for you.</Text>
                                        <CustomButton title='Learn More' onClick={()=>navigate('/service')} isNoBG isDark isChevron/>
                                    </div>
                                </div>
                                <div>
                                    <Image width={'100%'} height={'180'} style={{filter: 'brightness(80%)'}} src={Service02} alt="Revolutionize" withPlaceholder />
                                </div>
                            </div>
                        </Grid.Col>
                        <Grid.Col sm={6} md={6} lg="auto">
                            <div className={classes.section02Box02}>
                                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                                    <div style={{padding: 25}}>
                                        <Text size={16} fw={500} color='#292F83' lh={'normal'} pb={15}>Innovative</Text>
                                        <Text size={24} fw={700} color='#0B1023' lh={'normal'} pb={15}>Powerful App</Text>
                                        <Text size={15} fw={500} color='#0B1023' lh={'normal'} pb={30}>Delivering Unique Solutions for Your Specific Requirements.</Text>
                                        <CustomButton title='Learn More' onClick={()=>navigate('/service')} isNoBG isDark isChevron/>
                                    </div>
                                </div>
                                <div>
                                    <Image width={'100%'} height={'180'} style={{filter: 'brightness(80%)'}} src={Service03} alt="Innovative" withPlaceholder />
                                </div>
                            </div>
                        </Grid.Col>
                    </Grid>
                </div>
            </Container>
            {/* Section 03 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <div className={classes.section03ContentWrapper}>
                    <div className={classes.section03Box01}>
                        <div className={classes.section03ImageWapper}>
                            <div className={classes.section03ImageOffsetBorder}/>
                            <img className={classes.section03Image}  src={Key01} alt="Digitize"  />
                        </div>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Digitize</Text>
                            <Text className={classes.section03Title}>Transforming Ideas into Digital Solutions</Text>
                            <Text className={classes.section03Desc} pb={40}>At Sappot Tech, we are experts in creating tailored and flexible solutions for websites and mobile apps. Our dedicated team collaborates closely with clients to comprehend their individual requirements and provide top-notch solutions that go beyond expectations.</Text>
                            <Box>
                            <CustomButton title='Learn More' onClick={()=>navigate('/service')}/>
                            </Box>
                        </div>
                    </div>
                    <div className={classes.section03Box02}>
                        <div className={classes.section03ImageWapper}>
                            <div className={classes.section03ImageOffsetBorder}/>
                            {/* <Image classNames={{imageWrapper: classes.section03Image}}  fit='cover' src={Key02} alt="Innovative" withPlaceholder /> */}
                            <img className={classes.section03Image}  src={Key02} alt="Innovative"  />
                        </div>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Innovative</Text>
                            <Text className={classes.section03Title}>Empowering Your Success in the Digital Era</Text>
                            <Text className={classes.section03Desc} pb={20}>At Sappot Tech, we offer innovative website and mobile application development services that empower businesses to thrive in the digital age.</Text>
                            <Text className={classes.section03Desc} pb={8}>• Customized Solution</Text>
                            <Text className={classes.section03Desc} pb={8}>• Seamless Integration</Text>
                            <Text className={classes.section03Desc} pb={40}>• Exceptional User Experience</Text>
                            <Box>
                            <CustomButton title='Learn More' onClick={()=>navigate('/service')}/>
                            </Box>
                        </div>
                    </div>
                    <div className={classes.section03Box01}>
                        <div className={classes.section03ImageWapper}>
                            <div className={classes.section03ImageOffsetBorder}/>
                            {/* <Image classNames={{imageWrapper: classes.section03Image}}  fit='cover' src={Key01} alt="Quality" withPlaceholder /> */}
                            <img className={classes.section03Image}  src={Key03} alt="Quality"  />

                        </div>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Quality</Text>
                            <Text className={classes.section03Title}>Why Choose Sappot Tech?</Text>
                            <Text className={classes.section03Desc} pb={20}>At Sappot Tech, we understand that in today's fast-paced digital landscape, your online presence is crucial to the success of your business. That's why we are dedicated to providing exquisite services that go beyond the ordinary, offering a seamless blend of consultation and development for websites and mobile apps.</Text>
                            <Text className={classes.section03Desc} pb={40}>Choose Sappot Tech as your digital solutions provider, and let us elevate your online presence to new heights. Partner with us to experience excellence in consultation, website development, and mobile app creation. Your success is our mission!</Text>
                            <Box>
                            <CustomButton title='Learn More' onClick={()=>navigate('/service')}/>
                            </Box>
                        </div>
                    </div>
                </div>
            </Container>
            {/* Section 04 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <Text className={classes.sectionTitle} pb={5}>Testimonial</Text>
                <Text className={classes.sectionDesc}>Hear what our customers have to say about us</Text>
                <div className={classes.section02ContentWrapper}>
                <Carousel
                    withIndicators
                    slideGap="md"
                    loop
                    align="start"
                    nextControlIcon={<IconArrowRight size={16} />}
                    previousControlIcon={<IconArrowLeft size={16} />}
                    styles={{
                    indicator: {
                        width: rem(8),
                        height: rem(8),
                        background: '#D9D9D9',
                        transition: 'all 250ms ease',
            
                        '&[data-active]': {
                            background: '#34C6F5',
                        },
                    },
                    }}
                    >
                        {TESTIMONIAL.map((item, i)=>(
                            <Carousel.Slide key={i}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                                    <Rating defaultValue={item.rating} color="brand" size='lg' readOnly pb={50}/>
                                    <Text size={16} fw={500} color='#FFF' lh={'normal'} align='center' style={{maxWidth: 680}} pb={50}>“{item.review}”</Text>
                                    <Avatar variant="filled" color="dark" radius="xl" size="lg" src={item.logo} mb={15}/>
                                    <Text size={14} fw={600} color='#FFF' lh={'normal'} align='center' style={{maxWidth: 680}} pb={5}>{item.name}</Text>
                                    <Text size={12} fw={500} color='#FFF' lh={'normal'} align='center' style={{maxWidth: 680}} pb={85}>{item.position}, {item.company}</Text>
                                </div>
                            </Carousel.Slide>
                        ))}
                </Carousel>
                </div>
            </Container>
            {/* Section 05 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <Text className={classes.sectionTitle} pb={5}>Frequently Asked Questions</Text>
                <Text className={classes.sectionDesc}>Find answers to common questions about our services and processes.</Text>
                <div className={classes.section02ContentWrapper}>
                    <Container size={855}>
                    <Accordion variant="separated">
                        <Accordion.Item value={'0'}>
                            <Accordion.Control><Text style={{fontSize: 15, color: '#0B1023', fontWeight: 500, lineHeight: 'normal'}}>How do I get started?</Text></Accordion.Control>
                            <Accordion.Panel>
                                <Text style={{fontSize: 15, color: '#0B1023', fontWeight: 600, lineHeight: 'normal'}} pb={'xs'}>Getting started with our company is simple! Follow these steps:</Text>
                                <List type="ordered" size={15}>
                                    <List.Item><b>Contact Us:</b> Reach out to us through our website, email, or phone. Let us know your requirements and what services you need.</List.Item>
                                    <List.Item><b>Initial Consultation:</b> We'll schedule an initial consultation to understand your specific needs and goals.</List.Item>
                                    <List.Item><b>Proposal:</b> Based on our discussion, we'll provide you with a detailed proposal outlining the scope of work, timeline, and pricing.</List.Item>
                                    <List.Item><b>Agreement:</b> Once you're satisfied with the proposal, we'll formalize the agreement.</List.Item>
                                    <List.Item><b>Project Kick-off:</b> We'll start working on your project as per the agreed-upon timeline.</List.Item>
                                </List>
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value={'1'}>
                            <Accordion.Control><Text style={{fontSize: 15, color: '#0B1023', fontWeight: 500, lineHeight: 'normal'}}>What services do you offer?</Text></Accordion.Control>
                            <Accordion.Panel>
                                <Text style={{fontSize: 15, color: '#0B1023', fontWeight: 600, lineHeight: 'normal'}} pb={'xs'}>We offer a wide range of services tailored to meet your needs, including:</Text>
                                <List size={15}>
                                    <List.Item><b>Software Development:</b> Custom software and application development</List.Item>
                                    <List.Item><b>Web Development:</b> Design and development of websites and web applications.</List.Item>
                                    <List.Item><b>Mobile App Development:</b> iOS and Android app development.</List.Item>
                                    <List.Item><b>UI/UX Design:</b> User interface and user experience design</List.Item>
                                    <List.Item><b>Consulting:</b> Technology and strategy consulting.</List.Item>
                                    <List.Item><b>IT Solutions:</b> Comprehensive IT solutions for businesses.</List.Item>
                                    <List.Item><b>Digital Marketing:</b> SEO, SEM, social media, and online marketing.</List.Item>
                                    <List.Item><b>Support and Maintenance:</b >Ongoing support and maintenance for your digital solutions.</List.Item>
                                </List>
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value={'2'}>
                            <Accordion.Control><Text style={{fontSize: 15, color: '#0B1023', fontWeight: 500, lineHeight: 'normal'}}>How long does it take?</Text></Accordion.Control>
                            <Accordion.Panel>
                                <Text style={{fontSize: 15, color: '#0B1023', fontWeight: 600, lineHeight: 'normal'}} pb={'xs'}>The project timeline depends on the scope and complexity of your project. We'll provide you with a detailed project timeline and milestones in our proposal. Typically, timelines can range from a few weeks for smaller projects to several months for more extensive projects.</Text>
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value={'3'}>
                            <Accordion.Control><Text style={{fontSize: 15, color: '#0B1023', fontWeight: 500, lineHeight: 'normal'}}>What is your pricing?</Text></Accordion.Control>
                            <Accordion.Panel>
                                <Text style={{fontSize: 15, color: '#0B1023', fontWeight: 600, lineHeight: 'normal'}} pb={'xs'}>Our pricing varies based on the services you require and the complexity of the project. We offer competitive and flexible pricing models, including fixed-price, hourly rates, and retainer-based arrangements. Please contact us for a customized quote tailored to your specific needs.</Text>
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value={'4'}>
                            <Accordion.Control><Text style={{fontSize: 15, color: '#0B1023', fontWeight: 500, lineHeight: 'normal'}}>Do you offer support?</Text></Accordion.Control>
                            <Accordion.Panel>
                                <Text style={{fontSize: 15, color: '#0B1023', fontWeight: 600, lineHeight: 'normal'}} pb={'xs'}>Yes, we provide ongoing support and maintenance services to ensure the smooth operation of your digital solutions. Our support services include bug fixes, updates, security patches, and technical assistance. We offer various support plans to suit your requirements, and our team is available to address any issues promptly.</Text>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                    </Container>
                </div>
            </Container>
            {/* Section 06 */}
            <Container size={'xl'} className={classes.section06Container}>
                <Text className={classes.section06Title} pb={5}>Still have questions?</Text>
                <Text className={classes.section06Desc} pb={30}>Need more answers? Contact us now for more information</Text>
                <Center>
                    <CustomButton title='Contact Us' onClick={()=>navigate('/contact')}/>
                </Center>
            </Container>
        </div>
    )
}
