import React from 'react'
import { createStyles, Container, rem, Text, Group, TextInput, Textarea, Button, Box } from '@mantine/core'
import { IconAt, IconMapPin, IconPhone, IconSun } from '@tabler/icons-react';
import { useForm } from '@mantine/form';

import { HEADER_HEIGHT } from '../utils/Constants';

const useStyles = createStyles((theme) => ({
    root: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',
    },

    contactWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '40px 0px',
        [theme.fn.smallerThan('md')]: {
            padding: '40px 20px',
        },
    },

    contactContainer: {
        display: 'flex', gap: 40,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    contactInputContainer: {
        display: 'flex', flexDirection: 'column', gap: 20, backgroundColor: '#FFF', padding: '50px 40px', borderRadius: 5,
        [theme.fn.smallerThan('sm')]: {
            padding: '30px 20px'
        },
    },

    title: {
        fontSize: 36,
        fontWeight: 800,
        color: '#FFF',
        lineHeight: 'normal',
        paddingBottom: 15,
        [theme.fn.smallerThan('md')]: {
            fontSize: 28,
            // paddingBottom: 20,
        },
    },

    desc: {
        fontSize: 16,
        fontWeight: 400,
        color: '#FAFAFA',
        lineHeight: 'normal',
        [theme.fn.smallerThan('md')]: {
            fontSize: 14,
        },
    },

    contactStack: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 45,
        justifyContent: 'center',
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 40,
        },
    },

    contactLabel: {
        fontSize: 13,
        fontWeight: 400,
        color: '#CACACA',
        lineHeight: '22px',
    },

    contactText: {
        fontSize: 15,
        fontWeight: 500,
        color: '#FFF',
        lineHeight: '22px',
    },

    inputLabel: {
        fontSize: 13,
        fontWeight: 500,
        color: '#000',
        lineHeight: 'normal',
    },

    btnLabel: {
        fontSize: 13,
        fontWeight: 500,
        color: '#FFF',
        lineHeight: '22px',
    },

}));

export default function Contact() {
    const { classes } = useStyles()

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
    
        validate: {
            name: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            email: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            subject: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            message: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
        },
    });

    const onSubmitMessage = (data) => {
        let message = `Hi, I am ${data?.name}, I would like to inquire about ${data?.subject}. ${data?.message}. ${data?.email}`
        let url = `https://wa.me/60173736988?text=${encodeURIComponent(message)}`
        window.open(url)
    }

  return (
    <div className={classes.root}>
        <Container className={classes.contactWrapper}>
            <div className={classes.contactContainer}>
                <div style={{flex: 3, display: 'flex', flexDirection: 'column'}}>
                    <Text className={classes.title}>Contact Us</Text>
                    <Text className={classes.desc}>Feel free to leave us a message or contact us through our contact details.</Text>
                    <div className={classes.contactStack}>
                        <Group spacing={20}>
                            <IconAt size={35} color='#FFF'/>
                            <div>
                                <Text className={classes.contactLabel}>Email</Text>
                                <Text className={classes.contactText}>wkahlap@gmail.com</Text>
                            </div>
                        </Group>
                        <Group spacing={20}>
                            <IconPhone size={35} color='#FFF'/>
                            <div>
                                <Text className={classes.contactLabel}>Phone</Text>
                                <Text className={classes.contactText}>+6017 - 373 6988</Text>
                            </div>
                        </Group>
                        <Group spacing={20}>
                            <IconMapPin size={35} color='#FFF'/>
                            <div>
                                <Text className={classes.contactLabel}>Address</Text>
                                <Text className={classes.contactText}>C-02-19, Persiaran Kewajipan USJ 1,<br/>47600 Subang Jaya, Selangor</Text>
                            </div>
                        </Group>
                        <Group spacing={20}>
                            <IconSun size={35} color='#FFF'/>
                            <div>
                                <Text className={classes.contactLabel}>Working Hour</Text>
                                <Text className={classes.contactText}>9 a.m. - 5 p.m.</Text>
                            </div>
                        </Group>
                    </div>
                </div>
                <div style={{flex: 4}}>
                    <form onSubmit={form.onSubmit((values) => onSubmitMessage(values))}>
                        <div className={classes.contactInputContainer}>
                            <TextInput
                                placeholder=""
                                label="Name"
                                classNames={{label: classes.inputLabel}}
                                {...form.getInputProps('name')}
                            />
                            <TextInput
                                placeholder=""
                                label="Email"
                                classNames={{label: classes.inputLabel}}
                                {...form.getInputProps('email')}
                            />
                            <TextInput
                                placeholder=""
                                label="Subject"
                                classNames={{label: classes.inputLabel}}
                                {...form.getInputProps('subject')}
                            />
                            <Textarea
                                placeholder=""
                                label="Message"
                                minRows={4}
                                classNames={{label: classes.inputLabel}}
                                {...form.getInputProps('message')}
                            />
                            <Group position='right'>
                                <Box w={190}>
                                    <Button type='submit' classNames={{label: classes.btnLabel}} size='md' fullWidth>Send Message</Button>
                                </Box>
                            </Group>
                        </div>
                    </form>
                </div>
                
            </div>
        </Container>
    </div>
  )
}
