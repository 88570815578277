import {
    createStyles,
    Header,
    Container,
    Group,
    Burger,
    Paper,
    Transition,
    rem,
    UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
  
import SappotLogo from '../../assets/logo/SappotLogo.png'
import SappotText from '../../assets/logo/SappotText.png'

import { HEADER_HEIGHT } from '../../utils/Constants';
  
const useStyles = createStyles((theme) => ({
    root: {
        zIndex: 999,
        backgroundColor: 'rgba(0,0,0,0)',
        transition: 'all 0.25s ease',
    },

    headerBlur: {
        backgroundColor: 'rgba(0,0,0,0.38)',
        backdropFilter: 'blur(8px)',
    },

    dropdown: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        zIndex: 999,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: 'hidden',
        height: `calc(100lvh - ${rem(HEADER_HEIGHT)})`,
        backgroundColor: 'rgba(0,0,0,1)',

        [theme.fn.largerThan('sm')]: {
        display: 'none',
        },
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        [theme.fn.smallerThan('sm')]: {
            justifyContent: 'space-between',
        },
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
        display: 'none',
        },
    },

    burger: {
        [theme.fn.largerThan('sm')]: {
        display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(35)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: '#FFF',
        fontSize: 15,
        fontWeight: 500,

        '&:hover': {
        backgroundColor: 'rgba(125,125,125,0.2)',
        },

        [theme.fn.smallerThan('sm')]: {
        borderRadius: 0,
        fontSize: theme.fontSizes.md,
        padding: `${rem(18)} ${rem(16)}`,
        },

    },

    linkActive: {
        '&, &:hover': {
        color: theme.colors.brand[6],
        },
    },

    logo: {
        width: 30,
    },

    logoText: {
        width: 100,
    },

    dropdownActive: {
        backgroundColor: 'rgba(0,0,0,1)',
        backdropFilter: 'blur(8px)',
    },
}));

const links = [
    { label: 'Home', link: '/' },
    { label: 'Service', link: '/service' },
    { label: 'About Us', link: '/about' },
    { label: 'Contact Us', link: '/contact' },
]
  
export default function ZTRHeader() {
    const [opened, { toggle, close }] = useDisclosure(false);
    const { classes, cx } = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const [headerBlur, setHeaderBlur] = useState(false)

    const listenScrollEvent = (event) => {
        if (window.scrollY > HEADER_HEIGHT) {
            setHeaderBlur(true)
        } else {
            setHeaderBlur(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    return () =>
        window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const items = links.map((link) => (
        <Link
        key={link.label}
        to={link.link}
        className={cx(classes.link, { [classes.linkActive]: link.link === `/${location.pathname.split("/")[1]}` })}
        onClick={() => {
            close();
        }}
        >
        {link.label}
        </Link>
    ));

    return (
        <Header height={HEADER_HEIGHT} className={cx(classes.root, {[classes.headerBlur]: headerBlur === true},{ [classes.dropdownActive]: opened === true })} withBorder={false}>
        <Container className={classes.header} size={'xl'}>
            <UnstyledButton onClick={()=>navigate('/')} mr={35}>
                <Group spacing={'xs'}>
                    <img src={SappotLogo} className={classes.logo} alt='Sappot Tech'/>
                    <img src={SappotText} className={classes.logoText} alt='Sappot Tech'/>
                </Group>
            </UnstyledButton>
            
            <Group spacing={5} className={classes.links}>
            {items}
            </Group>

            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" color={'#FFF'}/>

            <Transition transition="fade" duration={250} mounted={opened}>
            {(styles) => (
                <Paper className={classes.dropdown} style={styles}>
                {items}
                </Paper>
            )}
            </Transition>
        </Container>
        </Header>
    );
}