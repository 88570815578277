import { BackgroundImage, Container, createStyles, Text, rem,  Center  } from '@mantine/core'
import { Carousel } from '@mantine/carousel';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useElementSize, useMediaQuery } from '@mantine/hooks';

import CustomButton from '../components/general/CustomButton';

import ServiceHeroBG from '../assets/images/ServiceHeroBG.png'

import Ellipse01 from '../assets/misc/Ellipse01.png'
import Ellipse02 from '../assets/misc/Ellipse02.png'

import Project01 from '../assets/images/Project01.png'
import Project02 from '../assets/images/Project02.png'
import Project03 from '../assets/images/Project03.png'

import { HEADER_HEIGHT } from '../utils/Constants';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },

    heroBG: {
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: -HEADER_HEIGHT,
        backgroundColor: '#0B1023',
    },

    heroSection: {
        minHeight: `calc(50svh)`,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        paddingTop: HEADER_HEIGHT,
        display: 'flex',
    },

    heroContentContainer: {
        marginTop: 40,
        marginBottom: 40,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 40,
    },

    heroTitle: {
        fontSize: 50,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 34,
        },
    },

    sectionAfterHero: {
        paddingTop: `calc(50svh - ${rem(HEADER_HEIGHT)})`,
    },

    sectionContainer: {
        paddingTop: 120,
        paddingBottom: 30,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 80,
            paddingBottom: 20,
        },
    },

    section01Wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 60,
        [theme.fn.smallerThan('lg')]: {
            gap: 40,
        },
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 40,
        },
    },

    section01ContentWrapper: {
        display: 'flex',
        gap: 60,
        [theme.fn.smallerThan('lg')]: {
            gap: 40,
        },
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 0,
        },
    },

    section: {
        paddingTop: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
    },

    sectionTitle: {
        fontSize: 40,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 30,
        },
    },

    sectionDesc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#CACACA',
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    section01Title: {
        fontSize: 36,
        fontWeight: 700,
        color: '#FFF',
        lineHeight: 'normal',
        paddingBottom: 40,
        [theme.fn.smallerThan('md')]: {
            fontSize: 28,
        },
    },

    section01Desc: {
        fontSize: 16,
        fontWeight: 400,
        color: '#FAFAFA',
        lineHeight: 'normal',
        textAlign: 'justify',
        [theme.fn.smallerThan('md')]: {
            fontSize: 14,
        },
    },

    section01Shape: {
        width: '50%',
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    section02ContentWrapper: {
        display: 'flex',
        gap: 40,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: 40,
        },
    },

    section02Content02Wrapper: {
        flex: 1, display: 'flex', flexDirection: 'column',
        gap: 2,
        [theme.fn.smallerThan('sm')]: {

        },
    },

    section02Title: {
        fontSize: 50,
        fontWeight: 800,
        lineHeight: 'normal',
        color: '#FFF',
        paddingBottom: 40,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 34,
            width: '100%',
        },
    },
    section02Desc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#FAFAFA',
        width: 600,
        textAlign: 'justify',

        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
            width: '100%',
        },
    },

    section03Image: {
        width: '100%',
    },

    section04Container: {
        paddingTop: 90,
        paddingBottom: 120,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 60,
            paddingBottom: 80,
        },
    },

}));


export default function Service() {
    const { classes } = useStyles()
    const navigate = useNavigate()
    const { ref, height } = useElementSize();
    const matches = useMediaQuery('(min-width: 48em)');

    return (
        <div className={classes.root}>
            {/* Hero */}
            <BackgroundImage
                src={ServiceHeroBG}
                className={classes.heroBG}
            >
                <div className={classes.heroSection}>
                    <Container size={'xl'} className={classes.heroContentContainer}>
                        <Text className={classes.heroTitle}>Our Services</Text>
                    </Container>
                </div>
            </BackgroundImage>
            {/* Section 01 */}
            <div className={classes.sectionAfterHero}>
                <Container size={'xl'} className={classes.sectionContainer}>
                    <div className={classes.section01Wrapper}>
                        <div className={classes.section01ContentWrapper}>
                            <div style={{flex: 1}} />
                            <div style={{flex: 1}}>
                                <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Expertise</Text>
                                <Text className={classes.section01Title}>Unlock Your Business Potential with Our Consultancy Services</Text>
                                <Text className={classes.section01Desc}>At Sappot Tech, we provide top-notch consultancy services to help your business thrive. Our team of experts will guide you through every step of the way, ensuring success in your ventures.</Text>
                            </div>
                        </div>
                        <div className={classes.section01ContentWrapper}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: height/2,}}>
                                <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Revolutionize</Text>
                                <Text className={classes.section01Title}>Creating custom websites for exceptional user experiences.</Text>
                                <Text className={classes.section01Desc}>At Sappot Tech, we specialize in creating websites with custom designs and exceptional user experiences. Our team of expert developers and designers work closely with clients to understand their unique needs and deliver websites that not only look great but also function flawlessly. With our attention to detail and commitment to quality, we ensure that every website we create is a true reflection of our clients' brand and helps them achieve their business goals.</Text>
                            </div>
                            <div style={{flex: 1,display: 'flex', flexDirection: 'column',}}>
                                <img ref={ref} className={classes.section01Shape} src={Ellipse01} alt=''/>
                            </div>
                        </div>
                        <div className={classes.section01ContentWrapper}>
                            <div style={{flex: 1,display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                <img className={classes.section01Shape} src={Ellipse02} alt=''/>
                            </div>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: height/2}}>
                                <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Innovative</Text>
                                <Text className={classes.section01Title}>Transforming Ideas into Powerful Mobile Apps</Text>
                                <Text className={classes.section01Desc}>At Sappot Tech, we offer cutting-edge mobile app development services that are designed to meet the unique needs of your business. Our team of experts combines innovative solutions with cross-platform capabilities to deliver powerful mobile apps that drive results.</Text>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Section 02 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <div className={classes.section02ContentWrapper}>
                    <div style={{flex: 1}}>
                        <Text size={16} fw={500} color='#34C6F5' lh={'normal'} pb={10}>Innovate</Text>
                        <Text className={classes.section02Title}>Empowering Businesses Through Cutting-Edge Solutions</Text>
                        <CustomButton title='Contact Us' onClick={()=>navigate('/contact')}/>
                    </div>
                    <div className={classes.section02Content02Wrapper}>
                        <div style={{display: 'flex', gap: 50, alignItems: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60,height: 60, borderRadius: 60, backgroundColor: '#34C6F5'}}>
                                <Text size={28} fw={600} color='#FFF' lh={'normal'}>1</Text>
                            </div>
                            <div style={{flex: 1}}>
                                <Text size={18} fw={600} color='#FFF' lh={'normal'} pb={10}>Consultation Process</Text>
                                <Text size={16} fw={400} color='#FAFAFA' lh={'normal'}>Schedule a consultation with our experts to discuss your project requirements.</Text>
                            </div>
                        </div>
                        <div style={{width: 1, height: 70, backgroundColor: '#34C6F5', marginLeft: 29}}/>
                        <div style={{display: 'flex', gap: 50, alignItems: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60,height: 60, borderRadius: 60, backgroundColor: '#34C6F5'}}>
                                <Text size={28} fw={600} color='#FFF' lh={'normal'}>2</Text>
                            </div>
                            <div style={{flex: 1}}>
                                <Text size={18} fw={600} color='#FFF' lh={'normal'} pb={10}>Development Phase</Text>
                                <Text size={16} fw={400} color='#FAFAFA' lh={'normal'}>Our team will work closely with you to design and develop your website or mobile app.</Text>
                            </div>
                        </div>
                        <div style={{width: 1, height: 70, backgroundColor: '#34C6F5', marginLeft: 29}}/>
                        <div style={{display: 'flex', gap: 50, alignItems: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60,height: 60, borderRadius: 60, backgroundColor: '#34C6F5'}}>
                                <Text size={28} fw={600} color='#FFF' lh={'normal'}>3</Text>
                            </div>
                            <div style={{flex: 1}}>
                                <Text size={18} fw={600} color='#FFF' lh={'normal'} pb={10}>Testing and Refinement</Text>
                                <Text size={16} fw={400} color='#FAFAFA' lh={'normal'}>We conduct rigorous testing to ensure your project meets the highest standards.</Text>
                            </div>
                        </div>
                        <div style={{width: 1, height: 70, backgroundColor: '#34C6F5', marginLeft: 29}}/>
                        <div style={{display: 'flex', gap: 50, alignItems: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60,height: 60, borderRadius: 60, backgroundColor: '#34C6F5'}}>
                                <Text size={28} fw={600} color='#FFF' lh={'normal'}>4</Text>
                            </div>
                            <div style={{flex: 1}}>
                                <Text size={18} fw={600} color='#FFF' lh={'normal'} pb={10}>Project Completion</Text>
                                <Text size={16} fw={400} color='#FAFAFA' lh={'normal'}>Once approved, we deliver the final product and provide ongoing support.</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            {/* Section 03 */}
            <Container size={'xl'} className={classes.sectionContainer}>
                <Text className={classes.sectionTitle} pb={5}>What We Do</Text>
                <Text className={classes.sectionDesc} pb={50}>Transforming your business needs into reality</Text>
                <Carousel
                    dragFree
                    slideSize={matches ? "50%" : "80%"}
                    slideGap="md"
                    // height={200}
                    initialSlide={1}
                    withIndicators
                    styles={{
                        indicator: {
                        width: rem(8),
                        height: rem(8),
                        transition: 'width 250ms ease',
                        background: '#D9D9D9',
            
                        '&[data-active]': {
                        width: rem(32),
                        background: '#34C6F5',
                        },
                    },
                    }}
                >
                    <Carousel.Slide>
                        <img className={classes.section03Image} src={Project01} alt="Project01"/>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <img className={classes.section03Image} src={Project02} alt="Project02"/>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <img className={classes.section03Image} src={Project03} alt="Project03"/>
                    </Carousel.Slide>
                </Carousel>
            </Container>
            {/* Section 04 */}
            <Container size={'xl'} className={classes.section04Container}>
                <Text className={classes.sectionTitle} pb={5}>Interested with our work?</Text>
                <Text className={classes.sectionDesc} pb={40}>Contact us now to discuss your project and receive a quote from our expert team.</Text>
                <Center>
                    <CustomButton title='Contact Us' onClick={()=>navigate('/contact')}/>
                </Center>
            </Container>
        </div>
    )
}
