import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import SappotAppShell from './components/general/SappotAppShell';

import Home from './pages/Home';
import Service from './pages/Service';
import About from './pages/About';
import Contact from './pages/Contact';

import NotFound from './pages/NotFound';

function App() {


  return (
    <Routes>
        <Route element={<SappotAppShell />}>
            {/* Public Route */}
            <Route path="/" element={<Home />} />
            <Route path="/service" element={<Service />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default App;
